<template>
  <div>
    <!-- <b-card>
      <b-row>
        <b-col md="10">
          <b-form-group
            label="ยูเซอร์ไอดี"
            label-for="customDelimiter"
          >
            <v-select
              v-model="search_userID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="names"
              :options="userslist"
              :reduce="(username) => username.username"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="2"
          class="p-0"
          style="margin-top: 20px"
        >
          <b-button
            block
            variant="gradient-dark"
            @click="
              $router.push({
                name: 'Deposit-Withdraw',
                params: { id: search_userID },
              }),
              changeUserEdit()
            "
          >
            <i class="far fa-sparkles" />
            ค้นหาข้อมูล
          </b-button>
        </b-col>
      </b-row>
    </b-card> -->

    <b-row>
      <b-col md="9">
        <b-card>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h4><i class="fa fa-users" /> แก้ไขข้อมูลลูกค้า</h4>
            </div>
            <!-- <b-col
              v-if="UserData.userID === 20"
              md="2"
              class="p-0"
              style="margin-top: 20px"
            >
              <b-form-input
                id="floating-label2"
                v-model="Edittelz"
                type="text"
                placeholder=""
              />
              <b-button
                block
                variant="gradient-dark"
                @click="Edittel(Edittelz)"
              >
                <i class="far fa-sparkles" />
                แก้ไขเบอร์โทร
              </b-button>
            </b-col> -->

            <!-- <div>
              <a
                class="btn btn-gradient-info mr-1"
                href="#deposit"
              >
                <i class="fas fa-arrow-up" /> ประวัติการฝาก
              </a>
              <a
                class="btn btn-gradient-info mr-1"
                href="#withdraw"
              >
                <i class="fas fa-arrow-down" /> ประวัติการถอน
              </a>
              <a
                class="btn btn-gradient-info mr-1"
                href="#play"
              >
                <i class="fas fa-magic" /> ประวัติการเล่น
              </a>
              <a
                class="btn btn-gradient-info text-white"
                @click="cancel"
              >
                <i class="fas fa-users" /> เพื่อนที่แนะนำ
              </a>
            </div> -->
          </div>
          <hr class="m-0 p-0">
          <!-- <h4><i class="fas fa-user-edit" /> แก้ไขข้อมูลลูกค้า</h4> -->
          <b-row class="mt-1">
            <b-col md="12">
              <b-form-group>
                <label>ชื่อ - นามสกุล <small class="text-danger">*</small></label>
                <b-form-input
                  id="floating-label2"
                  v-model="userDetails.fullname"
                  type="text"
                  placeholder=""
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <label>ยูสเซอร์เนม</label>
                <b-form-input
                  id="floating-label2"
                  v-model="userDetails.username"
                  type="text"
                  disabled
                  placeholder=""
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <label>รหัสผ่าน</label>
                <b-form-input
                  id="floating-label2"
                  v-model="userDetails.passwords"
                  type="text"
                  placeholder=""
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label>ธนาคาร</label>
                <v-select
                  v-model="userDetails.bank"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="banklist"
                  :reduce="(name) => name.code"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label>เลขที่บัญชี</label>
                <b-form-input
                  id="floating-label2"
                  v-model="userDetails.acc_no"
                  type="number"
                  placeholder=""
                />
              </b-form-group>
            </b-col>
            <!-- <b-col md="6">
              <b-form-group>
                <label>เบอร์ Truemoney Wallet</label>
                <b-form-input
                  id="floating-label2"
                  v-model="userDetails.trueaccno"
                  type="number"
                  placeholder=""
                />
              </b-form-group>
            </b-col> -->
            <b-col md="6">
              <b-form-group>
                <label>สถานะลูกค้า</label>
                <b-form-select
                  v-model="userDetails.status"
                  :options="options"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <label>Email</label>
                <b-form-input
                  id="floating-label2"
                  v-model="userDetails.email"
                  type="text"
                  placeholder=""
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="เลือกผู้แนะนำ"
                label-for="customDelimiter"
              >
                <b-overlay
                  :show="optionsMemShow"
                  variant="transparent"
                  opacity="0.99"
                  blur="5px"
                  rounded="sm"
                >
                  <template #overlay>
                    <div class="text-center">
                      <b-icon-controller
                        font-scale="2"
                        animation="cylon"
                      />
                    </div>
                  </template>
                  <div class="d-flex">
                    <div
                      v-if="optionsCheck === true"
                      class="m_vs"
                    >
                      <v-select
                        v-model="userDetails.invited_by"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="fullname"
                        :options="optionsMember"
                        :reduce="fullname => fullname.invite_code"
                      />
                    </div>
                    <div
                      v-else
                      class="m_vs mr-50"
                    >
                      <b-form-input
                        id="floating-label2"
                        v-model="userDetails.invited_by"
                        type="text"
                        placeholder="กรอกชื่อผู้แนะนำ"
                      />
                    </div>
                    <button
                      class="btn btn-outline-primary btn-sm"
                      @click="getUserslist(userDetails.invited_by , 'name')"
                    >
                      ค้นหา
                    </button>
                  </div>
                </b-overlay>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <div class="row">
                <div class="col-md-12">
                  <b-form-group>
                    <label>สถานะ Blacklist</label>
                    <b-form-checkbox
                      v-model="userDetails.blacklist"
                      checked="true"
                      class="custom-control-danger"
                      name="check-button"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </b-form-group>
                </div>

                <div class="col-md-12">
                  <b-form-group>
                    <label>สถานะ VIP</label>
                    <b-form-checkbox
                      v-model="userDetails.vip"
                      checked="true"
                      class="custom-control-info"
                      name="check-button"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </b-form-group>
                </div>

                <div class="col-md-12">
                  <b-form-group>
                    <label>ซื้อสลากเกินเวลาได้</label>
                    <b-form-checkbox
                      v-model="userDetails.overtime"
                      checked="true"
                      class="custom-control-info"
                      name="check-button"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </b-form-group>
                </div>

                <div class="col-md-12">
                  <b-form-group>
                    <label>ตั้งให้เป็นเซียน</label>
                    <b-form-checkbox
                      v-model="userDetails.zean"
                      class="custom-control-success"
                      name="check-button"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </b-form-group>

                  <b-form-group>
                    <label>% สำหรับเซียน</label>

                    <b-form-input
                      id="percent"
                      v-model="userDetails.zean_percent"
                      type="number"
                      placeholder=""
                      class="w-25"
                    />
                  </b-form-group>
                </div>
              </div>
            </b-col>

            <b-col md="8" />
            <b-col
              md="2"
              class="mt-1"
            >
              <b-form-group>
                <b-button
                  variant="gradient-dark"
                  block
                  @click="update_member()"
                >
                  <i class="fas fa-save" />
                  บันทึกข้อมูล
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="3">
        <div class="card-cradit white mb-1">
          <div class="p-1">
            <span>เครดิตคงเหลือ</span>
            <h3>{{ balance ? Commas(balance.toFixed(2)) : 0 }}</h3>
          </div>
          <div class="card-b d-flex justify-content-between">
            <small>เลขที่บัญชี : {{ userDetails.acc_no ? userDetails.acc_no : '-' }}</small>
            <small>เครดิตสมาชิก {{ balance ? Commas(balance.toFixed(2)) : 0 }}</small>
          </div>
        </div>

        <div class="card-cradit-blue white mb-1">
          <div class="p-1">
            <span>ยอดฝาก</span>
            <h3>{{ datalist.depamount ? Commas(datalist.depamount.toFixed(2)) : 0 }}</h3>
          </div>
          <div class="card-b d-flex justify-content-between">
            <small>-</small>
            <small>ฝากทั้งหมด {{ datalist.depamount ? Commas(datalist.depamount.toFixed(2)) : 0 }}</small>
          </div>
        </div>

        <div class="card-cradit-green white mb-1">
          <div class="p-1">
            <span>ยอดถอน</span>
            <h3>{{ datalist.withamount ? Commas(datalist.withamount.toFixed(2)) : 0 }}</h3>
          </div>
          <div class="card-b d-flex justify-content-between">
            <small>-</small>
            <small>{{ datalist.withamount ? Commas(datalist.withamount.toFixed(2)) : 0 }}</small>
          </div>
        </div>

        <div class="card-cradit-orange white mb-1">
          <div class="p-1">
            <span>ยอดรวมทั้งหมด</span>
            <h3>{{ datalist.total ? Commas(datalist.total.toFixed(2)) : 0 }}</h3>
          </div>
          <div class="card-b d-flex justify-content-between">
            <small>-</small>
            <small>-</small>
          </div>
        </div>

        <!-- <div class="card-cradit-white white mb-1">
          <div class="p-1">
            <div v-if="turn">
              <span>โปรปัจจุบัน</span>
              <hr>
              <div>
                <small>ชื่อโปรโมชั่น : {{ turn.promotion }}</small><br>
                <small>ยอดเทิร์นที่ต้องทำ : {{ turn.turnover }}</small><br>
                <small>ยอดเทิร์นปัจจุบัน : {{ turn.userturnover }}</small><br>
              </div>
              <hr>
            </div>
            <div>
              <b-button
                v-b-modal.modal-success
                variant="gradient-primary"
                block
                size="sm"
              >
                <i class="fad fa-history" />
                ประวัติรับโปร
              </b-button>
            </div><br>
            <div>
              <b-button
                variant="gradient-info"
                block
                size="sm"
                @click="unlock_turn()"
              >
                <i class="fad fa-history" />
                ปลดล๊อคเทิร์น
              </b-button>
            </div>
          </div>
          <div class="card-b d-flex justify-content-between">
            <small>-</small>
            <small>-</small>
          </div>
        </div> -->
      </b-col>
    </b-row>

    <b-overlay
      :show="show"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <div>
        <b-row class="match-height">
          <b-col md="6">
            <b-card no-body>
              <b-form-group class="p-1">
                <h3 id="deposit">
                  ประวัติการฝาก
                </h3>
              </b-form-group>
              <b-table
                striped
                hover
                responsive
                show-empty
                class="position-relative items-center"
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
              >
                <template #cell(index)="data">
                  {{ perPage * (currentPage - 1) + (data.index + 1) }}
                </template>
                <template #empty="scope">
                  <div class="p-2 text-center">
                    <img
                      src="/box.png"
                      alt="empty"
                      height="40px"
                    > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
                  </div>
                </template>
                <template #cell(picture)="data">
                  <b-avatar
                    size="lg"
                    :src="data.item.picture"
                  />
                </template>
                <template #cell(ip)="data">
                  <div class="text-nowrap">
                    <a
                      :href="`http://${data.item.ip}`"
                      target="_blank"
                    >{{
                      data.item.ip
                    }}</a>
                  </div>
                </template>
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <feather-icon
                      :id="`invoice-row-${data.item.id}-preview-icon`"
                      icon="FileTextIcon"
                      size="16"
                      class="mx-1"
                      @click="
                        $router.push({
                          name: 'dashboard',
                          params: { id: data.item.id },
                        })
                      "
                    />
                    <b-tooltip
                      title="History Deposit & Withdraw"
                      :target="`invoice-row-${data.item.id}-preview-icon`"
                    />
                  </div>
                </template>
              </b-table>

              <b-card-body
                class="d-flex justify-content-between flex-wrap pt-0"
              >
                <!-- page length -->
                <b-form-group
                  label="Per Page"
                  label-cols="8"
                  label-align="left"
                  label-size="sm"
                  label-for="sortBySelect"
                  class="text-nowrap mb-md-0 mr-1"
                >
                  <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    inline
                    :options="pageOptions"
                  />
                </b-form-group>

                <!-- pagination -->
                <div>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </b-card-body>
              <b-modal
                id="modal-1"
                title="Add Class"
                ok-only
                ok-title="Save"
              >
                <b-form-group
                  label="Class"
                  label-for="vi-first-name"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="CheckSquareIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-first-name"
                      placeholder=""
                    />
                  </b-input-group>
                </b-form-group>
              </b-modal>
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card no-body>
              <b-form-group class="mr-1 mb-md-0 p-2">
                <h3 id="withdraw">
                  รายการถอน
                </h3>
              </b-form-group>
              <b-table
                striped
                hover
                responsive
                show-empty
                class="position-relative items-center"
                :per-page="perPageWith"
                :current-page="currentPageWith"
                :items="items2"
                :fields="fields2"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
              >
                <template #cell(index)="data">
                  {{ perPageWith * (currentPageWith - 1) + (data.index + 1) }}
                </template>
                <template #empty="scope">
                  <div class="p-2 text-center">
                    <img
                      src="/box.png"
                      alt="empty"
                      height="40px"
                    > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
                  </div>
                </template>
                <template #cell(picture)="data">
                  <b-avatar
                    size="lg"
                    :src="data.item.picture"
                  />
                </template>
                <template #cell(ip)="data">
                  <div class="text-nowrap">
                    <a
                      :href="`http://${data.item.ip}`"
                      target="_blank"
                    >{{
                      data.item.ip
                    }}</a>
                  </div>
                </template>
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <feather-icon
                      :id="`invoice-row-${data.item.id}-preview-icon`"
                      icon="FileTextIcon"
                      size="16"
                      class="mx-1"
                      @click="
                        $router.push({
                          name: 'dashboard',
                          params: { id: data.item.id },
                        })
                      "
                    />
                    <b-tooltip
                      title="History Deposit &amp; Withdraw"
                      :target="`invoice-row-${data.item.id}-preview-icon`"
                    />
                    <!-- <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EditIcon"
            size="16"
            class="mx-1"
            @click="editData(data.item)"
          />
          <b-tooltip
            title="Preview Invoice"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          /> -->
                  </div>
                </template>
              </b-table>

              <b-card-body
                class="d-flex justify-content-between flex-wrap pt-0"
              >
                <!-- page length -->
                <b-form-group
                  label="Per Page"
                  label-cols="8"
                  label-align="left"
                  label-size="sm"
                  label-for="sortBySelect"
                  class="text-nowrap mb-md-0 mr-1"
                >
                  <b-form-select
                    id="perPageSelect"
                    v-model="perPageWith"
                    size="sm"
                    inline
                    :options="pageOptions"
                  />
                </b-form-group>

                <!-- pagination -->
                <div>
                  <b-pagination
                    v-model="currentPageWith"
                    :total-rows="totalRowsWith"
                    :per-page="perPageWith"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </b-card-body>
              <b-modal
                id="modal-1"
                title="Add Class"
                ok-only
                ok-title="Save"
              >
                <b-form-group
                  label="Class"
                  label-for="vi-first-name"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="CheckSquareIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-first-name"
                      placeholder=""
                    />
                  </b-input-group>
                </b-form-group>
              </b-modal>
            </b-card>
          </b-col>
          <!-- -------------------------------------------------------------- -->
          <b-col cols="12">
            <b-card no-body>
              <b-card-body>
                <div>
                  <b-row>
                    <b-col
                      cols="12"
                      md="4"
                      class="
                        d-flex
                        align-items-center
                        justify-content-start
                        mb-1 mb-md-0
                      "
                    >
                      <b-form-group class="mr-1 mb-md-0">
                        <h3 id="play">
                          รายการสลากลูกค้า
                        </h3>
                      </b-form-group>
                    </b-col>
                    <b-col md="4" />
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        label="ค้นหาตามงวดวันที่"
                        label-for="customDelimiter"
                      >
                        <!-- <flat-pickr
                          v-model="rangeDate"
                          class="form-control"
                          :config="{
                            mode: 'range',
                            enableTime: true,
                            dateFormat: 'Y-m-d H:i',
                            time_24hr: true,
                            defaultHour: 0,
                          }"
                          @change="getHistory"
                        /> -->

                        <b-form-datepicker
                          id="customDelimiter"
                          v-model="rangeDate"
                          @change="getHistory"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="1"
                      class="p-0"
                      style="margin-top: 20px"
                    >
                      <b-form-group>
                        <b-button
                          block
                          variant="gradient-primary"
                          @click="gethistory()"
                        >
                          ค้นหา
                        </b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-card-body>
              <b-table
                striped
                hover
                responsive
                show-empty
                class="position-relative items-center"
                :per-page="perPageHis"
                :items="itemsGame"
                :fields="fieldsGame"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
              >
                <template #cell(index)="data">
                  {{ perPageHis * (currentPageHis - 1) + (data.index + 1) }}
                </template>
                <template #empty="scope">
                  <div class="p-2 text-center">
                    <img
                      src="/box.png"
                      alt="empty"
                      height="40px"
                    > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
                  </div>
                </template>
                <template #cell(picture)="data">
                  <b-avatar
                    size="lg"
                    :src="data.item.picture"
                  />
                </template>
                <template #cell(ip)="data">
                  <div class="text-nowrap">
                    <a
                      :href="`http://${data.item.ip}`"
                      target="_blank"
                    >{{
                      data.item.ip
                    }}</a>
                  </div>
                </template>
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <div
                    v-if="data.item.productId === 'PG'"
                    class="text-nowrap"
                  >

                    <button
                      class="btn-show"
                      @click="showAction(data.item), printConsoleToHtml"
                    >
                      รายละเอียด
                    </button>

                    <b-modal
                      ref="my-action"
                      hide-footer
                      title="รายงานการเล่น"
                    >
                      <!-- <div> -->
                      <iframe
                        frameborder="0"
                        width="100%"
                        height="600px"
                        :src="gameResultUrl"
                        size="lg"
                        title="รายละเอียด"
                      />
                      <!-- </div> -->
                    </b-modal>
                    <!-- <feather-icon
                      :id="`invoice-row-${data.item.id}-preview-icon`"
                      icon="FileTextIcon"
                      size="16"
                      class="mx-1"
                      @click="
                        $router.push({
                          name: 'dashboard',
                          params: { id: data.item.id },
                        })
                      "
                    />
                    <b-tooltip
                      title="History Deposit & Withdraw"
                      :target="`invoice-row-${data.item.id}-preview-icon`"
                    /> -->
                    <!-- <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EditIcon"
            size="16"
            class="mx-1"
            @click="editData(data.item)"
          />
          <b-tooltip
            title="Preview Invoice"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          /> -->
                  </div>
                </template>
                <!-- Column: Actions -->
                <template #cell(status_show)="data">
                  <p
                    v-if="data.item.status === 2"
                  >
                    รอออกผลรางวัล
                  </p>
                  <p v-else-if="data.item.status === 3">
                    {{ data.item.reward_status }}
                  </p>
                  <p v-else-if="data.item.status === 4">
                    {{ data.item.reward_status }}
                  </p>
                </template>
                <template #cell(rewardshow)="data">
                  <p v-if="data.item.status === 3">
                    {{ data.item.reward_pay ? Commas(Number(data.item.reward_pay).toFixed(2)) : "0.00" }} W
                  </p>
                </template>
              </b-table>

              <b-card-body
                class="d-flex justify-content-between flex-wrap pt-0"
              >
                <!-- page length -->
                <b-form-group
                  label="Per Page"
                  label-cols="8"
                  label-align="left"
                  label-size="sm"
                  label-for="sortBySelect"
                  class="text-nowrap mb-md-0 mr-1"
                >
                  <b-form-select
                    id="perPageSelect"
                    v-model="perPageHis"
                    size="sm"
                    inline
                    :options="pageOptions"
                    @change="gethistory()"
                  />
                </b-form-group>

                <!-- pagination -->
                <div>
                  <b-pagination
                    v-model="currentPageHis"
                    :total-rows="totalRowsGame"
                    :per-page="perPageHis"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                    @input="gethistory()"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </b-card-body>
              <b-modal
                id="modal-1"
                title="Add Class"
                ok-only
                ok-title="Save"
              >
                <b-form-group
                  label="Class"
                  label-for="vi-first-name"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="CheckSquareIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-first-name"
                      placeholder=""
                    />
                  </b-input-group>
                </b-form-group>
              </b-modal>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  // asdas
  // BInputGroupAppend,
  BFormCheckbox,
  BRow,
  BCol,
  BInputGroupPrepend,
  BModal,
  BCard,
  BTable,
  BAvatar,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BButton,
  BCardBody,
  VBToggle,
  BOverlay,
  BIconController,
  BFormDatepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import moment from 'moment-timezone'
// import flatPickr from 'vue-flatpickr-component'
// import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    // BInputGroupAppend,
    BFormCheckbox,

    BRow,
    BCol,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    // StatisticCardWithAreaChart,
    // VueApexCharts,
    BOverlay,
    BIconController,
    vSelect,
    // flatPickr,
    BFormDatepicker,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      Edittelz: null,
      UserData: JSON.parse(localStorage.getItem('userData')),
      show: false,
      rangeDate: null,
      rangeDatePG: null,
      rangeDateSbo: null,
      balance: 0.0,
      today: '',
      perPage: 10,
      pageOptions: [10, 50, 100, 200, 500],
      totalRows: 1,
      totalRows2: 1,
      totalRowsGame: 1,
      currentPage: 1,
      perPageHis: 50,
      currentPageHis: 1,
      perPageHisPG: 50,
      itemsGamePG: [],
      totalRowsGamePG: 1,
      currentPageHisPG: 1,
      withamount: null,
      depamount: null,
      total: null,
      totalpro: null,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      banklist: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'username', label: 'username' },
        { key: 'amount', label: 'amount' },
        { key: 'bonus', label: 'bonus' },
        { key: 'balance', label: 'balance', formatter: value => Number(value).toFixed(2) },
        { key: 'status', label: 'status' },
        { key: 'time', label: 'เวลาสลิป' },
        {
          key: 'created_at',
          label: 'เวลา',
          formatter: value => moment(value).tz('Asia/Seoul').format('DD/MM/YYYY HH:mm:ss'),
        },
        // { key: 'actions', label: 'Actions' },
      ],
      itemsGame: [],
      /* eslint-disable global-require */
      items: [],
      fields2: [
        { key: 'index', label: 'no.' },
        { key: 'username', label: 'username' },
        { key: 'amount', label: 'amount' },
        { key: 'balance', label: 'balance', formatter: value => Number(value).toFixed(2) },
        { key: 'status', label: 'status' },
        {
          key: 'created_at',
          label: 'เวลา',
          formatter: value => moment(value).tz('Asia/Seoul').format('DD/MM/YYYY HH:mm:ss'),
        },
        // { key: 'actions', label: 'Actions' },
      ],
      seriesDep: [95],
      seriesWith: [75],
      seriesTotal: [65],
      chartOptionsDep: {
        chart: {
          height: 350,
          type: 'radialBar',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: '70%',
              background: 'tranparent',
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24,
              },
            },
            track: {
              background: '#fff',
              strokeWidth: '67%',
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35,
              },
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: '#888',
                fontSize: '17px',
              },
              value: {
                // formatter(val) {
                //   return parseInt(val)
                // },
                color: '#111',
                fontSize: '36px',
                show: true,
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#ABE5A1'],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        labels: ['ยอดฝาก'],
      },
      chartOptionsWith: {
        chart: {
          height: 350,
          type: 'radialBar',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: '70%',
              background: 'tranparent',
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24,
              },
            },
            track: {
              background: '#fff',
              strokeWidth: '67%',
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35,
              },
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: '#888',
                fontSize: '17px',
              },
              value: {
                // formatter(val) {
                //   return parseInt(val)
                // },
                color: '#111',
                fontSize: '36px',
                show: true,
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#ABE5A1'],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        labels: ['ยอดถอน'],
      },
      chartOptionsTotal: {
        chart: {
          height: 350,
          type: 'radialBar',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: '70%',
              background: 'tranparent',
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24,
              },
            },
            track: {
              background: '#fff',
              strokeWidth: '67%',
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35,
              },
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: '#888',
                fontSize: '17px',
              },
              value: {
                // formatter(val) {
                //   return parseInt(val)
                // },
                color: '#111',
                fontSize: '36px',
                show: true,
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#ABE5A1'],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        labels: ['ยอดรวมทั้งหมด'],
      },
      // data: {}, ++
      /* eslint-disable global-require */
      fieldsGame: [
        { key: 'index', label: 'no.' },
        { key: 'member_buy', label: 'ยูสเซอร์เนม' },
        { key: 'roundth', label: 'งวดประจำวันที่' },
        { key: 'lottonumber', label: 'เลขสลาก' },
        { key: 'status_show', label: 'สถานะ' },
        { key: 'rewardshow', label: 'เงินรางวัล' },
        // {
        //   key: 'created_at',
        //   label: 'เวลา',
        //   formatter: value => moment(value).tz('Asia/Seoul').format('DD/MM/YYYY HH:mm:ss'),
        // },

        // {
        //   key: 'created_at',
        //   label: 'created at',
        //   formatter: value => moment(value)
        //     .tz('Asia/Seoul')
        //     .format('DD/MM/YYYY HH:mm:ss'),
        // },
        // { key: 'actions', label: 'Actions' },
      ],
      items2: [],
      itemspro: [],
      search_userID: null,
      userDetails: [],
      userslist: [],
      addNewDataSidebar: false,
      depositSeries: [
        {
          name: 'deposit',
          data: [],
        },
      ],
      withdrawSeries: [
        {
          name: 'withdraw',
          data: [],
        },
      ],
      turn: '',
      turnhis: '',
      options: [
        { value: 1, text: 'Active: เข้าเล่นได้ปกติ' },
        { value: 2, text: 'Locked: เข้าสู่ล็อบบี้ไม่ได้ เข้าเล่นไม่ได้' },
        // { value: 0, text: 'Suspended: เข้าสู่ล็อบบี้ได้ แต่เข้าเล่นไม่ได้' },
      ],
      datalist: {
        depamount: 0,
        withamount: 0,
        total: 0,
      },
      optionsMember: [],
      optionsMemShow: false,
      optionsCheck: false,
      currentPageWith: 1,
      totalRowsWith: 0,
      perPageWith: 10,
      currentPagePro: 1,
      totalRowsPro: 0,
      perPagePro: 10,
      gameResultUrl: '',
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    // this.$http.get('/analytics/data').then(response => {
    //   this.data = response.data
    // })
  },
  async mounted() {
    this.getDepWit()
    this.Get_bank()
    this.getbalance()
    this.Getuserdetail()
  },
  methods: {
    showAction(item) {
      this.show = true
      const obj = {
        username: item.username,
        productId: item.productId,
        platformTxId: item.platformTxId,
        roundId: item.roundId,

      }
      this.$http
        .post('history/HistoryResultAWC', obj)
        .then(response => {
          // console.log(response.data)
          this.gameResultUrl = response.data.url
          this.show = false
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          // console.log(error)
          this.show = false
        })
      this.$refs['my-action'].show()
      // console.log(params)
    },
    unlock_turn() {
      this.$swal({
        title: 'แจ้งเตือน ?',
        text: 'ยืนยันที่จะปลดเทิร์นหรือไม่ ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.show = true
          const obj = {
            username: this.$route.params.id,
          }
          // console.log(Obj)
          this.$http
            .post('/promotion/unlockedturn', obj)
            .then(response => {
              this.getPro()
              if (response.data === 'success') {
                this.$swal({
                  icon: 'success',
                  title: 'สำเร็จ',
                  text: 'ปลดล็อคเทิร์นสำเร็จ',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              } else {
                this.show = false
                this.$swal({
                  icon: 'warning',
                  title: 'Error',
                  text: 'ปลดล็อคเทิร์นไม่สำเร็จ',
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                })
              }
            })
            .catch(error => console.log(error))
        } else {
          this.show = false
          this.$swal('ยกเลิก', 'ยกเลิกการปลดเทิร์น!', 'error')
        }
      })
    },
    getUserslist(data, type) {
      this.optionsMemShow = true
      if (data) {
        const params = {
          search: data,
          type,
        }
        this.$http
          .post('/users/list/search', params)
          .then(response => {
            if (response.data.length > 0) {
              this.optionsCheck = true
              this.userDetails.invited_by = response.data[0].invite_code
              if (type === 'name') {
                this.$swal({
                  icon: 'success',
                  title: '<h3 style="color: #000">ค้นหาสำเร็จ</h3>',
                  text: `พบข้อมูลชื่อผู้แนะนำ ${response.data.length} ราย`,
                  customClass: {
                    confirmButton: 'btn btn-warning',
                  },
                })
              }
            } else {
              this.userDetails.invited_by = null
              this.optionsCheck = false
              this.$swal({
                icon: 'danger',
                title: '<h3 style="color: #000">แจ้งเตือน !</h3>',
                text: 'ไม่พบข้อมูลผู้แนะนำ',
                customClass: {
                  confirmButton: 'btn btn-warning',
                },
              })
            }
            this.optionsMemShow = false
            this.optionsMember = response.data
          })
          .catch(error => console.log(error))
      } else {
        this.optionsMemShow = false
        this.optionsMember = []
        this.optionsCheck = false
        this.$swal({
          icon: 'danger',
          title: '<h3 style="color: #000">แจ้งเตือน !</h3>',
          text: 'กรุณากรอกชื่อผู้แนะนำเพื่อค้นหา',
          customClass: {
            confirmButton: 'btn btn-warning',
          },
        })
      }
    },
    Edittel(data) {
      const params = {
        tel: data,
        username: this.$route.params.id,
      }
      this.$http
        .post('/member/UpdateTel',
          params)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data)
          this.$router.push({ name: 'member' })
          this.$swal({
            icon: 'success',
            title: '<h3 style="color: #000">สำเร็จ</h3>',
            text: 'แก้ไขเบอร์โทรสำเร็จ',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(error => {
          this.$swal({
            icon: 'danger',
            title: '<h3 style="color: #000">แจ้งเตือน !</h3>',
            text: 'มีเบอร์โทรนี้อยู่ในระบบแล้ว',
            customClass: {
              confirmButton: 'btn btn-warning',
            },
          })
          console.log(error)
        })
    },
    getbalance() {
      this.show = true
      const params = {
        username: this.$route.params.id,
      }
      this.$http
        .get('/balance/getbalance', {
          params,
        })
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.balance = response.data.balance
        })
        .catch(error => console.log(error))
    },
    getHistory() {
      // console.log(this.rangeDate)
      const rangArray = this.rangeDate.split(' to ')
      // eslint-disable-next-line no-unused-vars
      const start = rangArray[0]
      // eslint-disable-next-line no-unused-vars
      const end = rangArray[1]
      // console.log(start)
      // console.log(end)
    },
    gethistory() {
      const rangArray = this.rangeDate.split(' to ')
      const start = rangArray[0]
      // const end = rangArray[1]
      this.show = true
      const obj = {
        username: this.$route.params.id,
        start,
        page: this.currentPageHis,
        perPage: this.perPageHis,
      }
      this.$http
        .post('/history/lotto', obj)
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.listdata = response.data.data
          this.onFilteredHis(response.data)
        })
        .catch(error => console.log(error))
    },
    onFilteredHis(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // console.log(filteredItems)
      this.itemsGame = filteredItems.data
      this.totalRowsGame = filteredItems.total
    },
    changeUserEdit() {
      this.getDepWit()
      this.Getuserdetail()
      this.Get_bank()
      this.Get_userlists()
      // this.gethistory()
      this.search_userID = null
    },
    Get_bank() {
      this.$http
        .get('/bank/korean')
        .then(response => {
          this.banklist = response.data
        })
        .catch(error => console.log(error))
    },
    Get_userlists() {
      this.$http
        .get('/users/list')
        .then(response => {
          this.userslist = response.data
        })
        .catch(error => console.log(error))
    },
    update_member() {
      if (this.userDetails.zean === true) {
        this.userDetails.zean = 1
      } else {
        this.userDetails.zean = 0
      }

      if (this.userDetails.blacklist === true) {
        this.userDetails.blacklist = 1
      } else {
        this.userDetails.blacklist = 0
      }
      if (this.userDetails.vip === true) {
        this.userDetails.vip = 1
      } else {
        this.userDetails.vip = 0
      }
      if (this.userDetails.overtime === true) {
        this.userDetails.overtime = 1
      } else {
        this.userDetails.overtime = 0
      }
      const params = {
        fullname: this.userDetails.fullname,
        password: this.userDetails.passwords,
        bank: this.userDetails.bank,
        acc_no: this.userDetails.acc_no,
        trueaccno: this.userDetails.trueaccno,
        status: this.userDetails.status,
        zean: this.userDetails.zean,
        zean_percent: this.userDetails.zean_percent,
        email: this.userDetails.email,
        invited_by: this.userDetails.invited_by,
        blacklist: this.userDetails.blacklist,
        vip: this.userDetails.vip,
        overtime: this.userDetails.overtime,
      }
      if (this.UserData.role === 'agadmin') {
        params.tel = this.userDetails.tel
      }
      // console.log(params)
      this.$http
        .post(
          `/users/update/${this.userDetails.id}`,
          params,
        )
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response)
          this.show = false
          this.Success('แก้ไขข้อมูลลูกค้าเรียบร้อย')
          this.Getuserdetail(response.data.username)
        })
        .catch(error => console.log(error))
    },
    async Getuserdetail(username = null) {
      if (!username) {
        // eslint-disable-next-line no-param-reassign
        username = this.$route.params.id
      }
      this.$http
        .get(
          `/users/showByusername/${username}`,
        )
        .then(response => {
          this.userDetails = response.data
          if (this.userDetails.zean === 1) {
            this.userDetails.zean = true
          } else {
            this.userDetails.zean = false
          }
          if (this.userDetails.blacklist === 1) {
            this.userDetails.blacklist = true
          } else {
            this.userDetails.blacklist = false
          }
          if (this.userDetails.vip === 1) {
            this.userDetails.vip = true
          } else {
            this.userDetails.vip = false
          }
          if (this.userDetails.overtime === 1) {
            this.userDetails.overtime = true
          } else {
            this.userDetails.overtime = false
          }
          if (this.userDetails.invited_by) {
            this.optionsCheck = true
            this.getUserslist(this.userDetails.invited_by, 'invited_by')
          }
        })
        .catch(error => console.log(error))
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    getDepWit() {
      this.$http
        .get(
          `/history/depwithlist/${this.$route.params.id}`,
        )
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.depositSeries[0].data = response.data.ddata
          this.withdrawSeries[0].data = response.data.wdata
          this.datalist = response.data
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // console.log(filteredItems)
      this.items = filteredItems.DataDep
      this.items2 = filteredItems.DataWith
      this.totalRows = filteredItems.DataDep.length
      this.totalRowsWith = filteredItems.DataWith.length
      // this.userData = filteredItems.userData
      this.withamount = filteredItems.withamount
      this.depamount = filteredItems.depamount
      this.total = filteredItems.total
      this.seriesDep = [filteredItems.depamount]
      this.seriesWith = [filteredItems.withamount]
      this.seriesTotal = [filteredItems.total]
      // console.log(this.userData)
    },
    Get_userlist() {
      this.$http
        .get('/api/user/list')
        .then(response => {
          this.onFiltered(response.data.message)
        })
        .catch(error => console.log(error))
    },
    addData() {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = {}
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = data
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #000">Error!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>
<style scoped>
iframe {
  border-width: 0 !important;
  overflow: clip !important;
  border-style: none !important;
    border-color: transparent !important;
    border-image: none !important;
}
.btn-show {
  background: #005d8873;
  color: #ffffff;
  border: none;
  border-radius: 15px;
  padding: 3px 10px;
  box-shadow: 0 0 10px 0 rgba(117, 22, 206, 0.2);
}
.m_vs {
  width: 500px;
  max-width: 500px;
}
label {
  font-size: 1rem !important;
  font-weight: 700 !important;
  color: black !important;
}
.card-warning {
  background-color: #ffb108;
  box-shadow: rgba(71, 71, 71, 0.589) 0 2px 10px;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.form-item-section {
  background-color: $product-details-bg;
}
</style>
